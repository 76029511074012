<template>
  <div class="headerTab-container">
    <div
      class="page-outter"
      style="width: 70%;min-width: 1300px;"
    >
      <div class="headerTab-inner">
        <img
          class="h-i-img"
          :src="require('../../assets/images/jyw.png')"
        />
        <el-menu
          :default-active="activeIndex"
          background-color="#070c17"
          text-color="#fff"
          active-text-color="#6AA5FF"
          class="el-menu-header"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <!-- <el-menu-item index="2">九医云医院</el-menu-item> -->
          <el-menu-item index="6">药店商城</el-menu-item>
          <el-submenu index="3">
            <template slot="title">九医药学院</template>
            <el-menu-item index="3-1">九药云零售药店管理系统</el-menu-item>
            <el-menu-item index="3-2">智能医药仓储管理平台</el-menu-item>
            <el-menu-item index="3-3">智慧药厂大数据管理平台</el-menu-item>
            <el-menu-item index="3-4">首营电子资料交换平台</el-menu-item>
            <el-menu-item index="3-5">产品溯源平台</el-menu-item>
            <el-menu-item index="3-6">宣教视频及其他</el-menu-item>
          </el-submenu>
          <el-menu-item index="4">关于我们</el-menu-item>
          <el-menu-item index="5">
            <el-button
              type="primary"
              @click="goLogo"
            >登录</el-button>
          </el-menu-item>
        </el-menu>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerTab",
  props: {
    activeIndex: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSelect(key) {
      let _this = this;
      switch (key) {
        case "1":
          _this.$router.push({ name: "home" });
          break;
        case "2":
          _this.$router.push({ name: "cloudHospital" });
          break;
        case "3-1":
          _this.$router.push({ name: "smartRetail" });
          break;
        case "3-2":
          _this.$router.push({ name: "smartMedicineWarehouse" });
          break;
        case "3-3":
          _this.$router.push({ name: "smartPharmaceuticalFactory" });
          break;
        case "3-4":
          _this.$router.push({ name: "electronicData" });
          break;
        case "3-5":
          _this.$router.push({ name: "productTraceability" });
          break;
        case "3-6":
          window.open('https://wlwh.91medicine.cn/');
          break;
        case "4":
          _this.$router.push({ name: "aboutUs" });
          break;
        case "5":
          // _this.$router.push({ name: "drugInfo" });
          break;
        case "6":
          _this.$router.push({ name: "mall" });
          break;
      }
    },
    goLogo() {
      window.open("https://chaindrugstore.91medicine.cn");
    },
  },
};
</script>

<style lang="less" scoped>
.headerTab-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 62px;
  background: #070c17;
  z-index: 1000;
}
.headerTab-inner {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h-i-img {
  width: 274px;
  height: 32px;
}
.el-menu-header {
  background: transparent;
  border-style: none;
  margin-top: 0px;
  color: #fff;
}
</style>